import PropTypes from "prop-types";

import { Container, Title, Text, Markdown } from "@gh/shared";

import * as styles from "./intro-text.module.scss";

export const IntroText = ({ title, content }) => (
  <Container>
    <Title wrapper="h1" size="s7" className={styles.title}>
      {title}
    </Title>
    <Text className={styles.content}>
      <Markdown content={content} />
    </Text>
  </Container>
);

IntroText.defaultProps = {
  title: null,
  content: null,
};

IntroText.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};
