import PropTypes from "prop-types";

import { Section } from "@gh/shared";

import { IntroText } from "../common/intro-text";

export const Intro = ({ data }) => (
  <Section align="center">
    <IntroText {...data} />
  </Section>
);

Intro.defaultProps = {
  data: {},
};

Intro.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,
};
