// extracted by mini-css-extract-plugin
export var arrow = "form-module--arrow--eAo20";
export var back = "form-module--back--oyWyi";
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var button = "form-module--button--83714";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var expandable = "form-module--expandable--8otsp";
export var field = "form-module--field--hCgZm";
export var message = "form-module--message--grXOJ";
export var open = "form-module--open--w2pvj";
export var remark = "form-module--remark--VRBpU";
export var retry = "form-module--retry--Ywwz5";
export var row = "form-module--row--Eorex";
export var rows = "form-module--rows--VSHir";
export var section = "form-module--section--wbyrj";
export var title = "form-module--title--zMhXT";