import { Fragment } from "react";
import { graphql } from "gatsby";

import { getProperty } from "@gh/shared";

import { Meta } from "./common/meta";
import { Intro } from "./request/intro";
import { Form } from "./request/form";

const Template = ({ data }) => {
  const meta = getProperty(data, "markdownRemark.frontmatter.meta");
  const intro = getProperty(data, "markdownRemark.frontmatter.request.intro");
  const form = getProperty(data, "markdownRemark.frontmatter.request.form");

  return (
    <Fragment>
      <Meta data={meta} />
      <Intro data={intro} />
      <Form data={form} />
    </Fragment>
  );
};

export default Template;

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        path
        title
        meta {
          title
          description
        }
        request {
          intro {
            title
            content
          }
          form {
            labels {
              submit
              retry
              expander
            }
            sections {
              title
              rows {
                fields {
                  name
                  type
                  label
                  required
                  options
                }
                expandable
              }
            }
            success {
              title
              content
            }
            failure {
              title
              content
            }
          }
        }
      }
    }
  }
`;
