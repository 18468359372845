import { useState, useRef, useEffect, useMemo } from "react";
import { useLocation } from "@reach/router";
import PropTypes from "prop-types";

import {
  ArrowIcon,
  Container,
  Text,
  Title,
  Markdown,
  Section,
  getProperty,
  Field,
  ButtonFlat,
  LinkButtonArrowBack,
} from "@gh/shared";

import { useMeta, usePageByView, useOffers } from "hooks";

import * as styles from "./form.module.scss";

const FORM_ATTRS = {
  action: "https://submit-form.com/QzxXY1iO",
  method: "POST",
  acceptCharset: "UTF-8",
  encType: "multipart/form-data",
};

const FIELDS_MAPPING = {
  CHECKBOX: Field.Checkbox,
  DATE: Field.Date,
  EMAIL: Field.Email,
  NUMBER: Field.Number,
  SELECT: Field.Select,
  TEXT: Field.Text,
  TEXTAREA: Field.TextArea,
};

const TRANSMISSION_STATUS = {
  IDLE: "1",
  SENDING: "2",
  SUCCESS: "3",
  FAILURE: "4",
  RETRYING: "5",
};

const fieldFactory = ({ type, ...fieldProps }, formProps) => {
  const Component = FIELDS_MAPPING[type];
  if (!Component) {
    return null;
  }

  return <Component {...fieldProps} {...formProps} />;
};

const valueProcessor = ({ type }, value) => {
  if (value) {
    return value;
  }

  switch (type) {
    case "CHECKBOX":
      return false;
    default:
      return "";
  }
};

const valueValidator = ({ required }, value) => {
  if (!required) {
    return true;
  }

  return !!value;
};

export const Form = ({ data }) => {
  const meta = useMeta();
  const page = usePageByView("home");
  const path = getProperty(page, "path");
  const remarkRef = useRef(null);
  const messageRef = useRef(null);
  const { search } = useLocation();
  const offers = useOffers();

  const [formState, setFormState] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [sectionState, setSectionState] = useState({});
  const [transmissionStatus, setTransmissionStatus] = useState(TRANSMISSION_STATUS.IDLE);

  const sections = getProperty(data, "sections") || [];
  const labelRetry = getProperty(data, "labels.retry");
  const labelSubmit = getProperty(data, "labels.submit");
  const labelExpander = getProperty(data, "labels.expander");
  const labelSending = getProperty(meta, "meta.form.status.sending");
  const errorMessage = getProperty(meta, "meta.form.error.required");

  const successTitle = getProperty(data, "success.title");
  const failureTitle = getProperty(data, "failure.title");
  const successContent = getProperty(data, "success.content");
  const failureContent = getProperty(data, "failure.content");

  const labelBackHome = getProperty(meta, "meta.navigation.label_back_home");

  const offer = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    const key = getProperty(meta, "meta.params.offer");
    return getProperty(offers, `offers.items.${searchParams.get(key)}.title`);
  }, [search, offers]);

  const expanderHandler = (key) => {
    setSectionState((current) => {
      const value = !!current[key];
      const next = { ...current };
      next[key] = !value;
      return next;
    });
  };

  const transmitData = async () => {
    const response = await fetch(FORM_ATTRS.action, {
      method: "post",
      body: JSON.stringify({
        ...formState,
        offer,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Failed with response: ${await response.text()}`);
    }
  };

  const retryHandler = async () => {
    try {
      setTransmissionStatus(TRANSMISSION_STATUS.RETRYING);
      await transmitData();
      setTransmissionStatus(TRANSMISSION_STATUS.SUCCESS);
    } catch (e) {
      setTransmissionStatus(TRANSMISSION_STATUS.FAILURE);
      console.error(e);
    }
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    const remark = getProperty(remarkRef, "current.value");
    if (remark) {
      return;
    }

    try {
      setTransmissionStatus(TRANSMISSION_STATUS.SENDING);
      await transmitData();
      setTransmissionStatus(TRANSMISSION_STATUS.SUCCESS);
    } catch (e) {
      setTransmissionStatus(TRANSMISSION_STATUS.FAILURE);
      console.error(e);
    }
  };

  const renderRow = (row, indexRow, indexSection) => {
    const key = `${indexSection}-${indexRow}`;
    const fields = getProperty(row, "fields") || [];
    const expandable = getProperty(row, "expandable");
    const expanded = !expandable || sections[indexRow] === true;

    return (
      <div key={key} className={styles.row} data-expanded={expanded}>
        {fields.map((field, indexField) => {
          const key = `${indexSection}-${indexRow}-${indexField}`;
          return (
            <div key={key} className={styles.field}>
              {fieldFactory(field, {
                submitted,
                error: valueValidator(field, formState[field.name]) ? null : errorMessage,
                value: valueProcessor(field, formState[field.name]),
                onChange: (value) => {
                  setFormState((form) => ({
                    ...form,
                    [field.name]: value,
                  }));
                },
              })}
            </div>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    switch (transmissionStatus) {
      case TRANSMISSION_STATUS.SUCCESS:
      case TRANSMISSION_STATUS.FAILURE: {
        const wrapper = getProperty(messageRef, "current");
        if (wrapper) {
          wrapper.scrollIntoView();
        }
        break;
      }
      default:
    }
  }, [messageRef, transmissionStatus]);

  return (
    <Section align="center">
      <Container>
        <ArrowIcon className={styles.arrow} />
        {transmissionStatus === TRANSMISSION_STATUS.SUCCESS && (
          <div ref={messageRef} className={styles.message}>
            <Title wrapper="h3" size="s7" weight="w4" color="primary">
              {successTitle}
            </Title>
            <Text>
              <Markdown content={successContent} />
            </Text>
            <div className={styles.back}>
              <LinkButtonArrowBack to={path}>{labelBackHome}</LinkButtonArrowBack>
            </div>
          </div>
        )}
        {(transmissionStatus === TRANSMISSION_STATUS.FAILURE ||
          transmissionStatus === TRANSMISSION_STATUS.RETRYING) && (
          <div ref={messageRef} className={styles.message}>
            <Title wrapper="h3" size="s7" weight="w4" color="error">
              {failureTitle}
            </Title>
            <Text>
              <Markdown content={failureContent} />
            </Text>
            <div className={styles.retry}>
              <ButtonFlat
                onClick={retryHandler}
                disabled={transmissionStatus === TRANSMISSION_STATUS.RETRYING}
              >
                <Text size="s4" wrapper="span" transform="uppercase">
                  {transmissionStatus === TRANSMISSION_STATUS.RETRYING ? labelSending : labelRetry}
                </Text>
              </ButtonFlat>
            </div>
          </div>
        )}
        {(transmissionStatus === TRANSMISSION_STATUS.IDLE ||
          transmissionStatus === TRANSMISSION_STATUS.SENDING) && (
          <form {...FORM_ATTRS} onSubmit={submitHandler}>
            {sections.map((section, indexSection) => {
              const title = getProperty(section, "title");
              const rows = getProperty(section, "rows") || [];

              const expanded = sectionState[indexSection] === true;
              const rowsDefault = rows.filter((row) => !getProperty(row, "expandable"));
              const rowsExpandable = rows.filter((row) => getProperty(row, "expandable"));

              return (
                <div key={indexSection} className={styles.section}>
                  <Title wrapper="h3" size="s7" weight="w4" className={styles.title}>
                    {title}
                  </Title>

                  <div className={styles.rows}>
                    {rowsDefault.map((...args) => renderRow(...args, indexSection))}
                  </div>

                  {rowsExpandable.length > 0 && (
                    <div className={`${styles.expandable} ${expanded ? styles.open : ""}`}>
                      <button
                        type="button"
                        className={styles.button}
                        onClick={() => expanderHandler(indexSection)}
                      >
                        {labelExpander}
                      </button>
                      <div className={styles.rows}>
                        {rowsExpandable.map((...args) => renderRow(...args, indexSection))}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}

            <input ref={remarkRef} type="text" className={styles.remark} />
            <ButtonFlat
              type="submit"
              onClick={() => setSubmitted(true)}
              disabled={transmissionStatus === TRANSMISSION_STATUS.SENDING}
            >
              <Text size="s4" wrapper="span" transform="uppercase">
                {transmissionStatus === TRANSMISSION_STATUS.SENDING ? labelSending : labelSubmit}
              </Text>
            </ButtonFlat>
          </form>
        )}
      </Container>
    </Section>
  );
};

Form.defaultProps = {
  data: {},
};

Form.propTypes = {
  data: PropTypes.shape({
    labels: PropTypes.shape({
      retry: PropTypes.string.isRequired,
      submit: PropTypes.string.isRequired,
      expander: PropTypes.string.isRequired,
    }).isRequired,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        rows: PropTypes.arrayOf(
          PropTypes.shape({
            fields: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
          })
        ),
      }).isRequired
    ).isRequired,
    success: PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    }).isRequired,
    failure: PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    }).isRequired,
  }),
};
